var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section-container p-0"},[_c('div',{staticClass:"text-center"},[_c('h6',{staticClass:"m-0 py-2 custom-text-overflow px-1"},[_vm._v(" "+_vm._s(_vm.schematicInformation.description || _vm.schematicInformation.title)+" "),(_vm.getUserAccess.Access.EDIT.project.sectionListTab.editSection && !_vm.currentProject.isArchived)?_c('small',{staticClass:"edit-icon text-muted",on:{"click":function($event){return _vm.$emit('open-edit-modal', _vm.schematicInformation)}}},[_c('i',{staticClass:"fa fa-edit"})]):_vm._e()]),_c('div',{staticClass:"px-2"},[_c('div',{staticClass:"py-2 mb-1 rounded single-header"},[_c('div',{staticClass:"custom-text-overflow",attrs:{"data-test":"schematic-section-position","title":_vm.schematicInformation.kilometrage}},[_vm._v(" "+_vm._s(_vm.formattedPosition(_vm.schematicInformation.kilometrage))+" ")]),(_vm.schematicInformation.boardOne && _vm.isDefaultDirection && _vm.schematicInformation.radarOnLeftSide == 1)?_c('div',{staticClass:"mb-auto mt-2"},[_c('div',{staticClass:"vwa-led-sign",class:{ 'vwa-led-sign-text': _vm.schematicInformation.state.vwaSettings.text !== '' },style:({
              height: _vm.shouldResize ? '113px' : '143px'
            }),on:{"click":function($event){return _vm.openDisplayModalDetails({
                sectionInfo: _vm.schematicInformation,
                displayPosition: _vm.FormattedSectionInf.displayPositionOne,
                vwa: true
              })}}},[_c('VwaLedSign',{key:_vm.vwaKey,attrs:{"vwa":{
                isVwa: true,
                vwaSettings: _vm.schematicInformation.state.vwaSettings,
                vwaSettingsActual: _vm.schematicInformation.state.stateDetails.deviceStateOne,
                deviceStatus: this.getSectionOneStatus
              },"backgroundSizeMid":_vm.backgroundSizeMid,"backgroundSizeHeightMid":_vm.backgroundSizeHeightMid,"marginTop":_vm.marginTop,"infoView":true}})],1),(_vm.FormattedSectionInf.haveBoardOne)?_c('b-badge',{staticClass:"d-block text-light py-2",attrs:{"variant":this.getClassByStatus(this.getSectionOneStatus)}},[_vm._v(" "+_vm._s(_vm.getSectionOneStatus)+" ")]):_vm._e(),(_vm.schematicInformation.state.stateDetails.deviceStateOne.status == 'INACTIVE' &&
                _vm.schematicInformation.state.stateDetails.deviceStateOne.supportEnabled)?_c('b-badge',{staticClass:"mt-1 d-block text-light py-2",attrs:{"variant":"inactive"}},[_vm._v(" "+_vm._s(_vm.schematicInformation.state.stateDetails.deviceStateOne.status)+" ")]):_vm._e()],1):_vm._e()])])]),(!_vm.currentProject.isRZP)?_c('div',{staticClass:"section-center my-auto"},[(_vm.schematicInformation.backgroundImage && _vm.schematicInformation.backgroundImage !== 'none.svg')?_c('b-img',{staticClass:"d-block",style:({ 'min-width': '150px', 'max-width': '150px' }),attrs:{"src":_vm.dynamicStyles(_vm.schematicInformation.backgroundImage)}}):_c('b-img',{style:({ 'min-width': '150px', 'max-width': '150px' }),attrs:{"src":require("@/assets/images/projects/RZP_BG/options/lines-none.svg")}})],1):_vm._e(),(_vm.currentProject.isRZP)?_c('div',{staticClass:"section-center my-auto"},[(!_vm.isDefaultDirection)?_c('b-img',{staticClass:"d-block",style:({ 'min-width': '150px', 'max-width': '150px' }),attrs:{"src":require("@/assets/images/projects/RZP_BG/options/png/lines-rzp-left.png")}}):_c('b-img',{staticClass:"d-block",style:({ 'min-width': '150px', 'max-width': '150px' }),attrs:{"src":require("@/assets/images/projects/RZP_BG/options/png/lines-rzp-right.png")}})],1):_vm._e(),_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"px-2 mt-2"},[_c('div',{staticClass:"py-2 mb-3 rounded single-header"},[_c('p',{staticClass:"custom-text-overflow",attrs:{"data-test":"schematic-section-position","title":_vm.schematicInformation.kilometrage}},[_vm._v(" "+_vm._s(_vm.formattedPosition(_vm.schematicInformation.kilometrage))+" ")]),(_vm.schematicInformation.radarOnLeftSide == 0 && _vm.schematicInformation.boardOne)?_c('div',{staticClass:"mb-auto mt-2"},[_c('div',{staticClass:"vwa-led-sign",class:{ 'vwa-led-sign-text': _vm.schematicInformation.state.vwaSettings.text !== '' },style:({
              height: _vm.shouldResize ? '113px' : '143px'
            }),on:{"click":function($event){return _vm.openDisplayModalDetails({
                sectionInfo: _vm.schematicInformation,
                displayPosition: _vm.FormattedSectionInf.displayPositionOne,
                vwa: true
              })}}},[_c('VwaLedSign',{key:_vm.vwaKey,attrs:{"vwa":{
                isVwa: true,
                vwaSettings: _vm.schematicInformation.state.vwaSettings,
                vwaSettingsActual: _vm.schematicInformation.state.stateDetails.deviceStateOne,
                deviceStatus: this.getSectionOneStatus
              },"backgroundSizeMid":_vm.backgroundSizeMid,"backgroundSizeHeightMid":_vm.backgroundSizeHeightMid,"marginTop":_vm.marginTop,"infoView":true}})],1),(_vm.FormattedSectionInf.haveBoardOne)?_c('b-badge',{staticClass:"d-block text-light py-2",attrs:{"variant":this.getClassByStatus(this.getSectionOneStatus)}},[_vm._v(" "+_vm._s(_vm.getSectionOneStatus)+" ")]):_vm._e(),(_vm.schematicInformation.state.stateDetails.deviceStateOne.status == 'INACTIVE' &&
                _vm.schematicInformation.state.stateDetails.deviceStateOne.supportEnabled)?_c('b-badge',{staticClass:"mt-1 d-block text-light py-2",attrs:{"variant":"inactive"}},[_vm._v(" "+_vm._s(_vm.schematicInformation.state.stateDetails.deviceStateOne.status)+" ")]):_vm._e()],1):_vm._e(),_c('span')])])])])}
var staticRenderFns = []

export { render, staticRenderFns }