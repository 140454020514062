
/**
 * Create new section  with the information type.
 *
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update to typescript.
 */

import { Component, Vue, Mixins, Prop, Watch } from "vue-property-decorator";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import Contains from "@/utils/SemiColValidator";
import SectionImagesSelection from "@/components/project/SingleProject/NewSectionTypes/SectionImagesSelection.vue";
import KmFormatted from "@/mixins/Project/KilometrageFromatted";
import UserAccess from "@/model/User/UserAccess";
import Project from "@/model/Project";
import { WEP_MOCK_URL } from "@/shared/keycloak";

import DisplayType from "@/components/project/SingleProject/Schematic/SinlgeViewTypes/DisplayType.vue";
import VwaPreWarningLamp from "@/components/project/SingleProject/Schematic/VwaPreWarningLamp.vue";
import VwaLedSign from "@/components/project/SingleProject/Schematic/VwaLedSign.vue";
import Section from "@/model/ModelSection";

@Component({
  name: "VwaType",
  components: {
    VwaLedSign,
    VwaPreWarningLamp,
    SectionImagesSelection
  },
  computed: {
    ...mapGetters(["getUserAccess"]),
    ...mapGetters("projects", {
      currentProject: "GetCurrentProject",
      currentSection: "GetCurrentSectionDetails",
    })
  },
  methods: {
    ...mapActions("projects", {
    }),
    ...mapMutations("projects", {
      RESET_CAMERA_PICTURE: "ResetCameraSectionLatestPicture",
      RESET_INFORMATION_MODAL: "ResetInformationModal",
      SET_MODAL_OPEN: "SET_MODAL_OPEN"
    }),
  },
  validations: {
    informationSection: {
      title: {
        required,
        minLength: minLength(2)
      },
      kilometrage: {
        required,
        minLength: minLength(2),
        notSemiCol: Contains(";")
      }
    }
  }
})
export default class VwaType extends Mixins(KmFormatted) {

  @Prop({type: Object, default: () => ({})}) schematicInformation!: any;

  WEP_MOCK_URL = WEP_MOCK_URL;
  currentProject!: Project;
  readonly getUserAccess!: UserAccess;
  private displayTypeInstance: any = null;
  readonly currentSection!: Section;

  informationSection = {
    title: "VWA",
    kilometrage: "000",
    img: "",
    backgroundImage: "none.svg"
  };

  backgroundSizeTop: String = "56px";
  backgroundSizeMid: String = "64px";
  backgroundSizeHeightMid: String = "55px";
  backgroundSizeFull: String = "143px";
  folderMid: String = "TrafficSignImagesMid64x72";
  folderBottom: String = "TrafficSignImagesBottom64x16";

  backgroundSizeBottom: String = "58px";
  backgroundSizeHeightBottom: String = "70px";

  marginTop: String = "0.55rem";
  changeImg: number = 1000;

  @Watch('vwaKey')
  onVwaKeyChanged() {
    this.changeBackgroundSizeOfVwaImages(false);
  }

  created() {
    this.changeBackgroundSizeOfVwaImages();
  }

  mounted() {
    this.reset();
  }

  changeBackgroundSizeOfVwaImages(vwaKeyChanged: boolean = false) {

    var vwaSettings: any = this.schematicInformation.state.vwaSettings;

    if (this.schematicInformation.state.vwaSettings.filenameMid.charAt(0) !== "m") {
      this.backgroundSizeMid = "20px";
      this.backgroundSizeHeightMid = "60px";
      this.marginTop = "";
    } else if (this.schematicInformation.state.vwaSettings.filenameMid.charAt(0) === "m") {

      this.backgroundSizeMid = "60px";

      if(vwaKeyChanged) {
        this.backgroundSizeMid = "70px";
      }

      this.backgroundSizeHeightMid = "54px";
    }
  }


  reset() {

    this.displayTypeInstance = new DisplayType();

    if (this.schematicInformation.state.vwaSettings) {
      this.changeBackgroundSizeOfVwaImages();
    }

    if(this.schematicInformation.state.vwaSettings.filenameMid.charAt(0) == 'm') {
      this.folderMid = "TrafficSignImagesMid64x72";
      this.folderBottom = "TrafficSignImagesBottom64x16";

      // this.backgroundSizeHeightMid = "54px";
      // this.backgroundSizeMid = "60px";

    } else {

      this.folderMid = "TrafficSignImagesBottom64x16";
      this.folderBottom = "TrafficSignImagesMid64x72";
    }
  }

  get isVwaOff(): boolean {
    // TODO: ersetzen mit VWA und nicht cwau
    var cwuSettings: any = this.schematicInformation.state.cwuSettings;

    var chooseOptions: any = {
      filename: cwuSettings.filename,
      filenameTop: cwuSettings.filenameTop,
      filenameMid: cwuSettings.filenameMid,
      filenameBottom: cwuSettings.filenameBottom,
      text: cwuSettings.text
    };

    if (this.areAllValuesEmpty(chooseOptions)) {
      return true;
    } else {
      return false;
    }
  }

  areAllValuesEmpty(obj: any) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] !== "") {
        return false;
      }
    }

    return true;
  }

  get isDefaultDirection(): boolean {
    return this.schematicInformation.direction === "DEFAULT";
  }
  dynamicStyles(name: string) {
    if (name === "none.svg" || !name) return;
    return require(`@/assets/images/projects/RZP_BG/options/png/lines-${name}`);
  }

  get getSectionOneStatus() {
    if (this.schematicInformation.state.stateDetails) {
      if (this.schematicInformation.state.stateDetails.deviceStateOne.supportEnabled) return "SUPPORT";
      else if (
        (this.schematicInformation.state.sceneSettings?.manualOverride ||
          this.schematicInformation.state.cwuSettings?.manualOverride ||
          this.schematicInformation.state.vsuSettings?.manualOverride) &&
        this.schematicInformation.state.stateDetails?.deviceStateOne?.status === "OK"
      )
        return "MANUELL";
      else return this.schematicInformation.state.stateDetails?.deviceStateOne?.status;
    } else {
      return "ALERT";
    }
  }

  getClassByStatus(status: any) {
    switch (status) {
      case "SUPPORT":
        return "info";
      case "MANUELL":
        return "manual-mode";
      case "OK":
        return "active";
      case "WARNING":
        return "pending";
      case "WAITING":
        return "inactive";
      case "INACTIVE":
        return "inactive";
      default:
        return "banned";
    }
  }

  get FormattedSectionInf(): any {
    return {
      haveBoardOne: this.schematicInformation.boardOne,
      displayOneType: {
        height: 144,
        name: "vwa",
        width: 64
      },
      deviceOneState: "deviceStateOne",
      displayPositionOne: "deviceOne",

      haveBoardTwo: this.schematicInformation.boardTwo,
      displayTwoType: {
        height: 144,
        name: "vwa",
        width: 64
      },
      deviceTwoState: "deviceStateTwo",
      displayPositionTwo: "deviceTwo"
    };
  }

  openDisplayModalDetails(displayInformation: any) {
    if (displayInformation.vwa) {
      this.getUserAccess.Access.VIEW.project.vwa.settings = true;
    } else {
      this.getUserAccess.Access.VIEW.project.vwa.settings = false;
    }

    this.$bvModal.show("openDisplayModalDetails");
    this.$emit("openDisplayModalDetails", displayInformation);

  }

  get shouldResize() {

    const { deviceStateOne } = this.schematicInformation.state.stateDetails;

    return (
      deviceStateOne.status === 'INACTIVE' &&
      deviceStateOne.supportEnabled
    );
  }


  get vwaKey(): string {
    const settings = this.schematicInformation.state.vwaSettings;

    return [
      settings.filename,
      settings.filenameTop,
      settings.filenameMid,
      settings.filenameBottom,
      settings.text,
      settings.textSize
    ].join("-");
  }

}
